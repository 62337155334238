import React from 'react';
import Layout from '../../components/Layout';
import { withIntl } from '../../i18n';
import CertificationPage from '../../templates/Certification';

const index = {
  header: {
    title: 'certificationTitle',
    subTitle: 'certificationSubTitle',
    image: 'sports_medal.png',
  },
  confirm: {
    image: 'corezoid-certification-badge.png'
  }
};


const Certification = (props) => (
  <Layout {...props} page={'certificationConfirm'}>
    <CertificationPage
      data={index}
    />
  </Layout>
);

export default withIntl(Certification);

