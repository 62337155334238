import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import {Button} from 'mw-style-react';
import './Certification.scss';
import HeaderPage from '../../components/HeaderPage/HeaderPage';
import { Link } from '../../i18n';
import Image from '../../components/Image/Image';
import Utils from '../../utils/utils';

class Certification extends PureComponent {
  componentWillMount() {
    if (typeof window !== 'undefined' && this.props.data.confirm) {
      window.__sharethis__ = null;
      Utils.addTag('script', {innerText: `
        window.shar = (function(d, s, id) {
          var js, fjs = d.getElementsByTagName(s)[0],
            t = {};
          js = d.createElement(s);
          js.id = id;
          js.src = '//platform-api.sharethis.com/js/sharethis.js#property=5bb1e6f67c574000111ca540&product=image-share-buttons';
          fjs.parentNode.insertBefore(js, fjs);
          t._e = [];
          t.ready = function(f) {
            console.log('ready', f)
          };
          return t;
        }(document, "script", "shar"));
      `});
    }
  }

  render() {
    const { data, intl } = this.props;

    return (
      <section className="certification">
        {data.confirm ?
          <div className="certification__confirm__wrapper">
            <div className="certification__header"/>
            <div className="certification__confirm">
              <Image file={data.confirm.image}/>
              {/* <div className="certification__confirm__share">*/}
                {/* <div className="sharethis-inline-share-buttons"/>*/}
              {/* </div>*/}
            </div>
          </div> :
          <HeaderPage data={data.header}/>
        }
        {data.cert &&
          <div className="certification__cert__wrapper">
            <div className="grid-wrapper certification__cert">
              <div className="col-7 certification__cert__title">
                {intl.formatMessage({id: data.cert.title})}
              </div>
              <div className="col-6 certification__cert__subTitle">
                {intl.formatMessage({id: data.cert.subTitle})}
              </div>
              <div className="col-12 grid-wrapper">
                {data.cert.data.map((item, index) => (
                  <div
                    key={index}
                    className="col-4 certification__cert__item__wrap"
                  >
                    <div className="certification__cert__item">
                      <Image file={item.image} />
                      <div className="certification__cert__item__title">{intl.formatMessage({id: item.title})}</div>
                      <div className="certification__cert__item__text">{intl.formatMessage({id: item.text})}</div>
                      <Link
                        to={intl.formatMessage({id: item.button.link})}
                        event={item.button.event}
                      >
                        <Button
                          className={'button__default certification__cert__item__btn'}
                          label={intl.formatMessage({id: item.button.text})}
                        />
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        }
        {data.corp &&
          <div className="certification__corp__wrapper">
            <div className="grid-wrapper certification__corp">
              <div className="col-9">
                <div className="certification__corp__title">
                  {intl.formatMessage({ id: data.corp.title })}
                </div>
                <div className="certification__corp__subTitle">
                  {intl.formatMessage({ id: data.corp.subTitle })}
                </div>
                <Link to={data.corp.button.link} event={data.corp.button.event}>
                  <Button
                    className={'button__default certification__corp__btn'}
                    label={intl.formatMessage({ id: data.corp.button.text })}
                  />
                </Link>
              </div>
            </div>
          </div>
        }
        {data.partner &&
          <div>
            <div className="grid-wrapper certification__corp">
              <div className="col-9">
                <div className="certification__corp__title">
                  {intl.formatMessage({id: data.partner.title})}
                </div>
                <div className="certification__corp__subTitle">
                  {intl.formatMessage({id: data.partner.subTitle})}
                </div>
                <Link to={data.partner.button.link} event={data.partner.button.event}>
                  <Button
                    className={'button__default certification__corp__btn'}
                    label={intl.formatMessage({id: data.partner.button.text})}
                  />
                </Link>
              </div>
            </div>
          </div>
        }
      </section>
    );
  }
}


Certification.propTypes = {
  data: PropTypes.object,
  intl: PropTypes.object,
};

export default injectIntl(Certification);

